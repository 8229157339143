import React from 'react'
import "./hero.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Hero = () => {
    const options = {
      loop: true,
      responsiveClass: true,
      center: true,
      items: 1,
      margin: 0,
      autoplay: true,
      dots: true,
      autoplayTimeout: 8500,
      smartSpeed: 450,
      nav: false,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 1,
        },
      },
    };

  return (
    <div className="slideShow">
      <OwlCarousel className="owl-theme" {...options}>
        <div class="item">
          <img src={require("../../img/he_1.jpg")} />
          <div className="h_text">
            <h5>
              Empowering Business Success with Expert Accounting & Consulting
            </h5>
            <p>
              Your trusted partner in financial strategy and compliance since
              2015.
            </p>
          </div>
        </div>
        <div class="item">
          <img src={require("../../img/he_2.jpg")} />
          <div className="h_text">
            <h5>Comprehensive Financial Solutions for Sustainable Growth</h5>
            <p>
              From accounting to tax advisory, we help businesses thrive in the
              UAE.
            </p>
          </div>
        </div>
        <div class="item">
          <img src={require("../../img/he_3.jpg")} />
          <div className="h_text">
            <h5>Your Premier Chartered Accountancy Firm in the UAE</h5>
            <p>
              Delivering expert financial guidance to mid- and large-sized
              corporations.
            </p>
          </div>
        </div>
      </OwlCarousel>
    </div>
  );
}

export default Hero
