import React from 'react'
import "./publications.scss";

const Publications = () => {
  return (
    <div className="__fw">
      <div className="__mw">
        <div className="publication_wrp">
          <div>
            <h5>Our Publications</h5>
          </div>
          <div>
            <p>
              {" "}
              Proceedings are the collection of academic papers published in the
              context of an academic conference.All the conferences above are
              now accepting full paper submission. Prospective authors are
              welcome to visit the website of conferences in which they are
              interested to find more details. All full paper submissions will
              be peer reviewed and evaluated based on originality, technical and
              /or research content / depth, correctness, relevance to
              conference, contributions, and readability. Conference
              Publications refers to all material that is published in relation
              to the conference, including but not limited to the conference's
              final program and the conference proceedings that result. All the
              paper we publish on conference web site at Research Library Page
              Research Library Conference proceeding book Also, we publish our
              magazine: THE EXECUTIVE RESEARCHER
            </p>
          </div>
          <div className="list_wrp">
            <h6>
              All selected papers will be published in the following Journals
            </h6>
            <ul>
              <li>
                International Journal of Management{" "}
                <em>(Online: ISSN 2424-6433)(Print:ISSN 2424-6425)</em>
              </li>
              <li>
                International Journal of economics{" "}
                <em>(Online: ISSN 2424-6417)(Print:ISSN 2424-6409)</em>
              </li>
              <li>
                International Journal of engineering science{" "}
                <em>(Online: ISSN 2424-645X)(Print:ISSN 2424-6441)</em>
              </li>
              <li>
                International Journal of technological advancement and research
                <em>(Online: ISSN 2424-6395)(Print:ISSN 2424-6387)</em>
              </li>
              <li>
                International Journal of Apparel and Textile{" "}
                <em>(Online: ISSN 2424-6557)(Print:ISSN 2424-6549)</em>
              </li>
              <li>
                International Journal of Health and medicine{" "}
                <em>(Online: ISSN 2424-6476)(Print:ISSN 2424-6468)</em>
              </li>
              <li>
                International Journal of food safety{" "}
                <em>(Online: ISSN 2424-6573)(Print:ISSN 2424-6565)</em>
              </li>
              <li>
                International Journal of education and distance learning
                <em>(Online: ISSN 2424-6514)(Print:ISSN 2424-6506)</em>
              </li>
              <li>
                International Journal of Law{" "}
                <em>(Online: ISSN 2424-6530)(Print:ISSN 2424-6522)</em>
              </li>
              <li>
                International Journal of social science and humanities{" "}
                <em>(Online: ISSN 2424-6492)(Print:ISSN 2424-6484)</em>
              </li>
              <p>Proceedings are published under GARI Publisher.</p>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Publications
