import React from 'react'
import "./registration.scss";
import RegForm from '../../components/form/RegForm';

const Ambassador = () => {
  return (
    <div className="__fw">
      <div className="__mw">
        <div className="reg_wrp">
          <div>
            <h5>Apply Country Ambassador</h5>
          </div>
          <div className="reg_in">
            <div className="reg_left">
              <p>
                The{" "}
                <em>GLOBAL ACADEMIC RESEARCH INSTITUTE (GARI conference)</em> is
                organizing international conference all around the world Every
                month for targeting diverse community of academics, researchers,
                developers, creators, educators, practitioners, professionals,
                industry leaders and representatives, designers, students, and
                other persons who are interesting in. Our Ultimate theme is
                "Making practitioners for Tomorrow". To achieve our objectives
                we need qualified "Country Ambassador" as GARI Conference
                representatives.
                <br />
                <br />
                <em>Expectations and brief of task as Country Ambassador</em>
                <br />
                <br />
                <ul>
                  <li>
                    Experience with sustaining strategic partnerships or
                    Connections with Universities, institutions, researchers,
                    Lectures, any research interest parties or Companies around
                    the Your Country and Demonstrated ability to develop
                    relationships with new engagements to achieve the GARI
                    objectives.
                  </li>
                  <li>
                    The successful applicant is responsible for the email
                    campaign and conducting promotion activities in your with
                    Colombo Operation office.
                  </li>
                  <li>
                    As a Country Ambassador, you are keen to extend a research
                    and training support for your civil society as well as GARI.
                  </li>
                  <li>
                    As the country’s ambassador for GARI you will be responsible
                    for all research and development activities conducting
                    workshops and conferences across in your Country with
                    Colombo Operation office.
                  </li>
                </ul>
                <br />
                <em>The scholars who want to be with us please Apply.</em>
              </p>
            </div>
            <div className="reg_right">
              <RegForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ambassador
