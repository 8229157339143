import React from "react";
import "./aboutUs.scss";

const AboutUs = () => {
  return (
    <div className="__fw">
      <div className="__mw">
        <div className="about_wrp">
          <div className="about_left">
            <div className="about_img">
              <img src={require("../../img/about.jpg")} alt="" />
            </div>
          </div>
          <div className="about_right">
            <div className="r_top">
              <h3>
                Global Academic Research Institute (GARI) is{" "}
                <em>international scientific research conference organizer</em>{" "}
                in collaboration with international universities & institutions.
              </h3>
            </div>
            <div className="r_down">
              <h3>About GARI</h3>
              <p>
                GARI have brought together leading academic and industry
                professionals from different countries to share global knowledge
                with everyone. We bring diversity experience and expertise in
                different academic or scientific disciplines.
              </p>
            </div>
          </div>
        </div>
        <div>
          <p>
            Business management, Economics, Health & Medicine, Ayurveda &
            Traditional medicine, Education, Peace & conflict management,
            Sustainability, Engineering & technology, Social science, law,
            election & democracy, language & literature, distance learning,
            leadership, sport science, aerospace, robotics and many more and
            scientific conference host in Sri Lanka as well as USA, Thailand,
            Portugal, India, China and many countries in the world.
            <br />
            <br />
            We established on 2010 as independent service provider then wider
            range of experience GARI became a powerful arm of scientific
            research conference organizer in the industry. Sri Lanka has
            more-than 2500 years long history of culture and Education, that
            exposure we share with world today. Our symbolic hospitality for
            participant became via our tradition. So every single scientist
            treated with the greatest care and attention.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
