import React from 'react'
import logo_01 from "../../img/logo_01.jpg";
import "./advisoryTeam.scss";

const Ambassadors = () => {
     const journal = [
          {
            img: logo_01,
            headding: "GARI International Journal of Multidisciplinary Research",
            des: "The official peer-reviewed journal of the “GARI INTERNATIONAL JOURNAL OF MULTIDISCIPLINARY RESEARCH” ISSN 2659-2193 (Online) is An Open Access Double Blind Peer Reviewed Quarterly Publish E- Journal, with a strong Editorial Board and a tested rapid p...",
            link: "https://www.research.lk",
            issn: "ISSN 2659-2193",
          },
          {
            img: logo_01,
            headding: "International Journal of Health and Medicine",
            des: `The official peer-reviewed journal of the “International Journal of Health and Medicine" (Online: ISSN 2424-6476) is An Open Access Double Blind Peer Reviewed Quarterly Publish E- Journal, with a strong Editorial Board and a tested rapid peer review...`,
            link: "https://www.research.lk/journal/list-of-journals",
            issn: "ISSN 2424-6476",
          },
          {
            img: logo_01,
            headding:
              "International Journal of Ayurveda, Traditional Medicine, Siddha, Unani and Medicinal Plants",
            des: `The official peer-reviewed journal of the “International Journal of Ayurveda, Traditional Medicine, Siddha, Unani and Medicinal Plants" (Online: ISSN 3084-8849) is An Open Access Double Blind Peer Reviewed Quarterly Publish E- Journal, with a strong...`,
            link: "https://www.research.lk/journal/list-of-journals",
            issn: "ISSN 3084-8849",
          },
          {
            img: logo_01,
            headding: "International Journal of Management",
            des: `The official peer-reviewed journal of the “International Journal of Management" (Online: ISSN 2424-6433) is An Open Access Double Blind Peer Reviewed Quarterly Publish E- Journal, with a strong Editorial Board and a tested rapid peer review system.`,
            link: " https://www.research.lk/journal/list-of-journals",
            issn: "ISSN 2424-6433",
          },
        
        
          
        ];
  return (
    <div className="__fw">
      <div className="__mw">
        <div className="journal_wrp">
          <div>
            <h5>Ambassadors</h5>
          </div>
          <div className="journalCards">
            {journal.map((item, i) => (
              <div className="card" key={i}>
                <div className="img">
                  <div className="img_in">
                    <img src={item.img} alt="" />
                  </div>
                </div>
                <div className="p_dec">
                  <h6>{item.headding}</h6>
                  <p>{item.des}</p>
                  <small>
                    Website: <a href={item.link}>{item.link}</a>{" "}
                  </small>
                  <small>Online: {item.issn} </small>
                  <span>More Info: Click Here</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ambassadors
