import React from "react";
import "./regForm.scss";

const RegForm = () => {
  return (
    <div className="form_wrp">
      <div>
        <h5>Apply Here</h5>
      </div>
      <form action="">
        <div>
          <select name="designation">
            <option value="">--- Select Designation ---</option>
            <option value="Prof">Prof</option>
            <option value="Dr">Dr</option>
            <option value="Mr">Mr</option>
            <option value="Ms">Ms</option>
            <option value="Mrs">Mrs</option>
          </select>
        </div>
        <div>
          <input type="text" name="name" value="" placeholder="Enter Name" />
        </div>
        <div>
          <input
            type="text"
            name="email"
            value=""
            class="form-control"
            placeholder="Enter Email"
          />
        </div>
        <div>
          <select name="subconference_id">
            <option value="Technology and Innovation for Social Movements (ICTISM)">
              Technology and Innovation for Social Movements (ICTISM)
            </option>
            <option value="main site">main site</option>
            <option value="Ayurveda, Traditional Medicine, Siddha, Uniani, and Medicinal Plants">
              Ayurveda, Traditional Medicine, Siddha, Uniani, and Medicinal
              Plants
            </option>
            <option value="Health and Medicine">Health and Medicine</option>
            <option value="Ayurveda, Traditional Medicine, Siddha, Unani, and Medicinal Plants">
              Ayurveda, Traditional Medicine, Siddha, Unani, and Medicinal
              Plants
            </option>
            <option value="Social Science and Humanities">
              Social Science and Humanities
            </option>
            <option value="Business Management and Economics">
              Business Management and Economics
            </option>
            <option value="Engineering, Science and Technology">
              Engineering, Science and Technology
            </option>
            <option value="Apparel, Textiles and Fashion Design">
              Apparel, Textiles and Fashion Design
            </option>
            <option value="Colour, Culture and Modern Art">
              Colour, Culture and Modern Art
            </option>
            <option value="Sustainable Development">
              Sustainable Development
            </option>
            <option value="Peace and Conflict Management">
              Peace and Conflict Management
            </option>
            <option value="Education and Distance Learning">
              Education and Distance Learning
            </option>
            <option value="Election and Democracy">
              Election and Democracy
            </option>
            <option value="Community Medicine and Public Health">
              Community Medicine and Public Health
            </option>
            <option value="Health and Medicine">Health and Medicine</option>
            <option value="Non Communicable Diseases">
              Non Communicable Diseases
            </option>
            <option value="Heritage and Culture">Heritage and Culture</option>
            <option value="Music, Drama, Visual &amp; Performing Arts">
              Music, Drama, Visual &amp; Performing Arts
            </option>
            <option value="Sociology &amp; Law">Sociology &amp; Law</option>
            <option value="Language and Literature Studies">
              Language and Literature Studies
            </option>
            <option value="Library and Information Science">
              Library and Information Science
            </option>
            <option value="Multidisciplinary Research Publications and Networking">
              Multidisciplinary Research Publications and Networking
            </option>
            <option value="Business Management &amp; Law">
              Business Management &amp; Law
            </option>
            <option value="Women's Rights">Women's Rights</option>
            <option value="African Studies">African Studies</option>
            <option value="Leisure and Tourism">Leisure and Tourism</option>
            <option value="Coffee, Tea and Wine Studies">
              Coffee, Tea and Wine Studies
            </option>
            <option value="Nursing and Healthcare">
              Nursing and Healthcare
            </option>
            <option value="Contemporary issues of Commerce, Education, Arts and Information Technology">
              Contemporary issues of Commerce, Education, Arts and Information
              Technology
            </option>
            <option value="Applied Science">Applied Science</option>
            <option value="Food Resources and Security">
              Food Resources and Security
            </option>
            <option value="Marketing Management">Marketing Management</option>
            <option value="Entrepreneurship">Entrepreneurship</option>
            <option value="Arts, Business and Education">
              Arts, Business and Education
            </option>
            <option value="Sustainable Design and Creative Industries">
              Sustainable Design and Creative Industries
            </option>
            <option value="Power Crisis Management">
              Power Crisis Management
            </option>
            <option value="Agri-business Marketing">
              Agri-business Marketing
            </option>
            <option value="Renewable Energy">Renewable Energy</option>
            <option value="Computer Science and Information Technology">
              Computer Science and Information Technology
            </option>
            <option value="Women’s and Children’s Health">
              Women’s and Children’s Health
            </option>
            <option value="Corporate Governance">Corporate Governance</option>
            <option value="Social Media, SEO &amp; Marketing Strategies">
              Social Media, SEO &amp; Marketing Strategies
            </option>
            <option value="Corporate Social Responsibility">
              Corporate Social Responsibility
            </option>
            <option value="Mechanical Engineering">
              Mechanical Engineering
            </option>
            <option value="Artificial Intelligence and Robotics Technology">
              Artificial Intelligence and Robotics Technology
            </option>
            <option value="Civil and Transport Engineering">
              Civil and Transport Engineering
            </option>
            <option value="Political Science">Political Science</option>
            <option value="Journalism">Journalism</option>
            <option value="Public Administration">Public Administration</option>
            <option value="Digital Teaching and Learning">
              Digital Teaching and Learning
            </option>
            <option value="Pharmaceutical Sciences">
              Pharmaceutical Sciences
            </option>
            <option value="GLBT Studies">GLBT Studies</option>
            <option value="Hospital and Healthcare Management">
              Hospital and Healthcare Management
            </option>
            <option value="Knowledge, Culture and Society">
              Knowledge, Culture and Society
            </option>
            <option value="Poverty and Social Protection">
              Poverty and Social Protection
            </option>
            <option value="Spirituality, Psychology and Religion">
              Spirituality, Psychology and Religion
            </option>
            <option value="Innovation in Sciences, Management and Technology">
              Innovation in Sciences, Management and Technology
            </option>
            <option value="Micro Finance Management &amp; Developing Countries Bank Behavior">
              Micro Finance Management &amp; Developing Countries Bank Behavior
            </option>
            <option value="Marine Science and Technology for Sustainable River and Ocean">
              Marine Science and Technology for Sustainable River and Ocean
            </option>
            <option value="Infectious Diseases">Infectious Diseases</option>
            <option value="Mental Health">Mental Health</option>
            <option value="Water, Rain Forest, Agriculture, Aquaculture, Biodiversity &amp; Environment">
              Water, Rain Forest, Agriculture, Aquaculture, Biodiversity &amp;
              Environment
            </option>
            <option value="Petroleum, Oil and Gas Sustainability">
              Petroleum, Oil and Gas Sustainability
            </option>
            <option value="Urban Studies &amp; Smart Materials">
              Urban Studies &amp; Smart Materials
            </option>
            <option value="Defense and Security Management">
              Defense and Security Management
            </option>
            <option value="Evidence Based Traditional Medicine">
              Evidence Based Traditional Medicine
            </option>
            <option value="Business Management and Economics Italy">
              Business Management and Economics Italy
            </option>
            <option value="Business Management and Economics Oxford">
              Business Management and Economics Oxford
            </option>
            <option value="Community Medicine and Public Health lisbon">
              Community Medicine and Public Health lisbon
            </option>
            <option value="Nursing and Healthcare Colombo">
              Nursing and Healthcare Colombo
            </option>
            <option value="Social Science and Humanities Colombo">
              Social Science and Humanities Colombo
            </option>
            <option value="Business Management and Economics Colombo">
              Business Management and Economics Colombo
            </option>
            <option value="Sociology &amp; Law Colombo">
              Sociology &amp; Law Colombo
            </option>
            <option value="Language and Literature Studies Colombo">
              Language and Literature Studies Colombo
            </option>
            <option value="Engineering, Science and Technology Colombo">
              Engineering, Science and Technology Colombo
            </option>
            <option value="Social Science and Humanities Oxford">
              Social Science and Humanities Oxford
            </option>
            <option value="Health and Medicine Ponta Delgada">
              Health and Medicine Ponta Delgada
            </option>
            <option value="Social Science and Humanities Budapest">
              Social Science and Humanities Budapest
            </option>
            <option value="Heritage and Culture Paris">
              Heritage and Culture Paris
            </option>
            <option value="Agri-business Marketing Paris">
              Agri-business Marketing Paris
            </option>
            <option value="Education and Distance Learning Paris">
              Education and Distance Learning Paris
            </option>
            <option value="Business Management and Economics Spain">
              Business Management and Economics Spain
            </option>
            <option value="Social Science and Humanities Spain">
              Social Science and Humanities Spain
            </option>
            <option value="Sociology &amp; Law Azores">
              Sociology &amp; Law Azores
            </option>
            <option value="Coffee, Tea and Wine Studies Azores">
              Coffee, Tea and Wine Studies Azores
            </option>
            <option value="Language and Literature Studies Azores">
              Language and Literature Studies Azores
            </option>
            <option value="Hospital and Healthcare Management Azores">
              Hospital and Healthcare Management Azores
            </option>
            <option value="Business Management and Economics Portugal">
              Business Management and Economics Portugal
            </option>
            <option value="Social Science and Humanities Portugal">
              Social Science and Humanities Portugal
            </option>
            <option value="Social Science and Humanities Croatia">
              Social Science and Humanities Croatia
            </option>
            <option value="Business Management and Economics Croatia">
              Business Management and Economics Croatia
            </option>
            <option value="Political Science Greece">
              Political Science Greece
            </option>
            <option value="Social Science and Humanities  Greece">
              Social Science and Humanities Greece
            </option>
            <option value="Business Management and Economics Greece">
              Business Management and Economics Greece
            </option>
            <option value="Public Administration Romania">
              Public Administration Romania
            </option>
            <option value="Corporate Governance Romania">
              Corporate Governance Romania
            </option>
            <option value="Business Management and Economics Romania">
              Business Management and Economics Romania
            </option>
            <option value="Social Science and Humanities Romania">
              Social Science and Humanities Romania
            </option>
            <option value="Innovation in Sciences, Management and Technology Slovenia">
              Innovation in Sciences, Management and Technology Slovenia
            </option>
            <option value="Education and Distance Learning Slovenia">
              Education and Distance Learning Slovenia
            </option>
            <option value="Business Management and Economics Slovenia">
              Business Management and Economics Slovenia
            </option>
            <option value="Social Science and Humanities  Slovenia">
              Social Science and Humanities Slovenia
            </option>
            <option value="Community Medicine and Public Health Slovakia">
              Community Medicine and Public Health Slovakia
            </option>
            <option value="Non Communicable Diseases Slovakia">
              Non Communicable Diseases Slovakia
            </option>
            <option value="Business Management and Economics Slovakia">
              Business Management and Economics Slovakia
            </option>
            <option value="Social Science and Humanities  Slovakia">
              Social Science and Humanities Slovakia
            </option>
            <option value="Heritage and Culture Czech Republic">
              Heritage and Culture Czech Republic
            </option>
            <option value="Leisure and Tourism Czech Republic">
              Leisure and Tourism Czech Republic
            </option>
            <option value="Women’s and Children’s Health Poland">
              Women’s and Children’s Health Poland
            </option>
            <option value="Health and Medicine Poland">
              Health and Medicine Poland
            </option>
            <option value="Community Medicine and Public Health Poland">
              Community Medicine and Public Health Poland
            </option>
            <option value="Engineering, Science and Technology Poland">
              Engineering, Science and Technology Poland
            </option>
            <option value="Political Science Azores">
              Political Science Azores
            </option>
            <option value="Spirituality, Psychology and Religion Azores">
              Spirituality, Psychology and Religion Azores
            </option>
            <option value="Business Management and Economics Azores">
              Business Management and Economics Azores
            </option>
            <option value="Social Science and Humanities Azores">
              Social Science and Humanities Azores
            </option>
          </select>
        </div>
        <div>
          <select name="s_category">
            <option value="">--- Select Category ---</option>
            <option value="Co-chair">Co-chair</option>
            <option value="Keynotes">Keynotes</option>
            <option value="Scientific Reviewer">Scientific Reviewer</option>
          </select>
        </div>
        <div>
          <select
            name="country"
            class="form-control custom-select my-1 mr-sm-2"
          >
            <option value="">--- Select Country ---</option>
            <option value="Afghanistan">Afghanistan</option>
            <option value="Albania">Albania</option>
            <option value="Algeria">Algeria</option>
            <option value="American Samoa">American Samoa</option>
            <option value="Andorra">Andorra</option>
            <option value="Angola">Angola</option>
            <option value="Anguilla">Anguilla</option>
            <option value="Antarctica">Antarctica</option>
            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
            <option value="Argentina">Argentina</option>
            <option value="Armenia">Armenia</option>
            <option value="Aruba">Aruba</option>
            <option value="Australia">Australia</option>
            <option value="Austria">Austria</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Bahamas">Bahamas</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Barbados">Barbados</option>
            <option value="Belarus">Belarus</option>
            <option value="Belgium">Belgium</option>
            <option value="Belize">Belize</option>
            <option value="Benin">Benin</option>
            <option value="Bermuda">Bermuda</option>
            <option value="Bhutan">Bhutan</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Bosnia and Herzegovina">
              Bosnia and Herzegovina
            </option>
            <option value="Botswana">Botswana</option>
            <option value="Bouvet Island">Bouvet Island</option>
            <option value="Brazil">Brazil</option>
            <option value="British Indian Ocean Territory">
              British Indian Ocean Territory
            </option>
            <option value="Brunei Darussalam">Brunei Darussalam</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Burkina Faso">Burkina Faso</option>
            <option value="Burundi">Burundi</option>
            <option value="Cambodia">Cambodia</option>
            <option value="Cameroon">Cameroon</option>
            <option value="Canada">Canada</option>
            <option value="Cape Verde">Cape Verde</option>
            <option value="Cayman Islands">Cayman Islands</option>
            <option value="Central African Republic">
              Central African Republic
            </option>
            <option value="Chad">Chad</option>
            <option value="Chile">Chile</option>
            <option value="China">China</option>
            <option value="Christmas Island">Christmas Island</option>
            <option value="Cocos (Keeling) Islands">
              Cocos (Keeling) Islands
            </option>
            <option value="Colombia">Colombia</option>
            <option value="Comoros">Comoros</option>
            <option value="Congo">Congo</option>
            <option value="Congo, the Democratic Republic of the">
              Congo, the Democratic Republic of the
            </option>
            <option value="Cook Islands">Cook Islands</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Cote D'Ivoire">Cote D'Ivoire</option>
            <option value="Croatia">Croatia</option>
            <option value="Cuba">Cuba</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Czech Republic">Czech Republic</option>
            <option value="Denmark">Denmark</option>
            <option value="Djibouti">Djibouti</option>
            <option value="Dominica">Dominica</option>
            <option value="Dominican Republic">Dominican Republic</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Egypt">Egypt</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Equatorial Guinea">Equatorial Guinea</option>
            <option value="Eritrea">Eritrea</option>
            <option value="Estonia">Estonia</option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Falkland Islands (Malvinas)">
              Falkland Islands (Malvinas)
            </option>
            <option value="Faroe Islands">Faroe Islands</option>
            <option value="Fiji">Fiji</option>
            <option value="Finland">Finland</option>
            <option value="France">France</option>
            <option value="French Guiana">French Guiana</option>
            <option value="French Polynesia">French Polynesia</option>
            <option value="French Southern Territories">
              French Southern Territories
            </option>
            <option value="Gabon">Gabon</option>
            <option value="Gambia">Gambia</option>
            <option value="Georgia">Georgia</option>
            <option value="Germany">Germany</option>
            <option value="Ghana">Ghana</option>
            <option value="Gibraltar">Gibraltar</option>
            <option value="Greece">Greece</option>
            <option value="Greenland">Greenland</option>
            <option value="Grenada">Grenada</option>
            <option value="Guadeloupe">Guadeloupe</option>
            <option value="Guam">Guam</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Guinea">Guinea</option>
            <option value="Guinea-Bissau">Guinea-Bissau</option>
            <option value="Guyana">Guyana</option>
            <option value="Haiti">Haiti</option>
            <option value="Heard Island and Mcdonald Islands">
              Heard Island and Mcdonald Islands
            </option>
            <option value="Holy See (Vatican City State)">
              Holy See (Vatican City State)
            </option>
            <option value="Honduras">Honduras</option>
            <option value="Hong Kong">Hong Kong</option>
            <option value="Hungary">Hungary</option>
            <option value="Iceland">Iceland</option>
            <option value="India">India</option>
            <option value="Indonesia">Indonesia</option>
            <option value="Iran, Islamic Republic of">
              Iran, Islamic Republic of
            </option>
            <option value="Iraq">Iraq</option>
            <option value="Ireland">Ireland</option>
            <option value="Israel">Israel</option>
            <option value="Italy">Italy</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Japan">Japan</option>
            <option value="Jordan">Jordan</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Kenya">Kenya</option>
            <option value="Kiribati">Kiribati</option>
            <option value="Korea, Democratic People's Republic of">
              Korea, Democratic People's Republic of
            </option>
            <option value="Korea, Republic of">Korea, Republic of</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="Lao People's Democratic Republic">
              Lao People's Democratic Republic
            </option>
            <option value="Latvia">Latvia</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Lesotho">Lesotho</option>
            <option value="Liberia">Liberia</option>
            <option value="Libyan Arab Jamahiriya">
              Libyan Arab Jamahiriya
            </option>
            <option value="Liechtenstein">Liechtenstein</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Macao">Macao</option>
            <option value="Macedonia, the Former Yugoslav Republic of">
              Macedonia, the Former Yugoslav Republic of
            </option>
            <option value="Madagascar">Madagascar</option>
            <option value="Malawi">Malawi</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Maldives">Maldives</option>
            <option value="Mali">Mali</option>
            <option value="Malta">Malta</option>
            <option value="Marshall Islands">Marshall Islands</option>
            <option value="Martinique">Martinique</option>
            <option value="Mauritania">Mauritania</option>
            <option value="Mauritius">Mauritius</option>
            <option value="Mayotte">Mayotte</option>
            <option value="Mexico">Mexico</option>
            <option value="Micronesia, Federated States of">
              Micronesia, Federated States of
            </option>
            <option value="Moldova, Republic of">Moldova, Republic of</option>
            <option value="Monaco">Monaco</option>
            <option value="Mongolia">Mongolia</option>
            <option value="Montserrat">Montserrat</option>
            <option value="Morocco">Morocco</option>
            <option value="Mozambique">Mozambique</option>
            <option value="Myanmar">Myanmar</option>
            <option value="Namibia">Namibia</option>
            <option value="Nauru">Nauru</option>
            <option value="Nepal">Nepal</option>
            <option value="Netherlands">Netherlands</option>
            <option value="Netherlands Antilles">Netherlands Antilles</option>
            <option value="New Caledonia">New Caledonia</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Niger">Niger</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Niue">Niue</option>
            <option value="Norfolk Island">Norfolk Island</option>
            <option value="Northern Mariana Islands">
              Northern Mariana Islands
            </option>
            <option value="Norway">Norway</option>
            <option value="Oman">Oman</option>
            <option value="Pakistan">Pakistan</option>
            <option value="Palau">Palau</option>
            <option value="Palestinian Territory, Occupied">
              Palestinian Territory, Occupied
            </option>
            <option value="Panama">Panama</option>
            <option value="Papua New Guinea">Papua New Guinea</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Peru</option>
            <option value="Philippines">Philippines</option>
            <option value="Pitcairn">Pitcairn</option>
            <option value="Poland">Poland</option>
            <option value="Portugal">Portugal</option>
            <option value="Puerto Rico">Puerto Rico</option>
            <option value="Qatar">Qatar</option>
            <option value="Reunion">Reunion</option>
            <option value="Romania">Romania</option>
            <option value="Russian Federation">Russian Federation</option>
            <option value="Rwanda">Rwanda</option>
            <option value="Saint Helena">Saint Helena</option>
            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
            <option value="Saint Lucia">Saint Lucia</option>
            <option value="Saint Pierre and Miquelon">
              Saint Pierre and Miquelon
            </option>
            <option value="Saint Vincent and the Grenadines">
              Saint Vincent and the Grenadines
            </option>
            <option value="Samoa">Samoa</option>
            <option value="San Marino">San Marino</option>
            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Senegal">Senegal</option>
            <option value="Serbia and Montenegro">Serbia and Montenegro</option>
            <option value="Seychelles">Seychelles</option>
            <option value="Sierra Leone">Sierra Leone</option>
            <option value="Singapore">Singapore</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Solomon Islands">Solomon Islands</option>
            <option value="Somalia">Somalia</option>
            <option value="South Africa">South Africa</option>
            <option value="South Georgia and the South Sandwich Islands">
              South Georgia and the South Sandwich Islands
            </option>
            <option value="Spain">Spain</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="Sudan">Sudan</option>
            <option value="Suriname">Suriname</option>
            <option value="Svalbard and Jan Mayen">
              Svalbard and Jan Mayen
            </option>
            <option value="Swaziland">Swaziland</option>
            <option value="Sweden">Sweden</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
            <option value="Taiwan, Province of China">
              Taiwan, Province of China
            </option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Tanzania, United Republic of">
              Tanzania, United Republic of
            </option>
            <option value="Thailand">Thailand</option>
            <option value="Timor-Leste">Timor-Leste</option>
            <option value="Togo">Togo</option>
            <option value="Tokelau">Tokelau</option>
            <option value="Tonga">Tonga</option>
            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Turkey">Turkey</option>
            <option value="Turkmenistan">Turkmenistan</option>
            <option value="Turks and Caicos Islands">
              Turks and Caicos Islands
            </option>
            <option value="Tuvalu">Tuvalu</option>
            <option value="Uganda">Uganda</option>
            <option value="Ukraine">Ukraine</option>
            <option value="United Arab Emirates">United Arab Emirates</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="United States">United States</option>
            <option value="United States Minor Outlying Islands">
              United States Minor Outlying Islands
            </option>
            <option value="Uruguay">Uruguay</option>
            <option value="Uzbekistan">Uzbekistan</option>
            <option value="Vanuatu">Vanuatu</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Viet Nam">Viet Nam</option>
            <option value="Virgin Islands, British">
              Virgin Islands, British
            </option>
            <option value="Virgin Islands, U.s.">Virgin Islands, U.s.</option>
            <option value="Wallis and Futuna">Wallis and Futuna</option>
            <option value="Western Sahara">Western Sahara</option>
            <option value="Yemen">Yemen</option>
            <option value="Zambia">Zambia</option>
            <option value="Zimbabwe">Zimbabwe</option>
          </select>
        </div>
        <div>
          <input
            type="text"
            name="phone"
            value=""
            class="form-control"
            placeholder="Enter Phone"
          ></input>
        </div>
        <div>
          <input
            type="text"
            name="linkedin"
            value=""
            class="form-control"
            placeholder="Enter LinkedIn ID"
          ></input>
        </div>
        <div>
          <input type="file" name="document" class="form-control"></input>
        </div>
        <div>
          <textarea
            name="message"
            class="form-control"
            placeholder="Enter Message"
            rows="3"
          ></textarea>
        </div>
        <div>
          <button className="btn">Apply</button>
        </div>
      </form>
    </div>
  );
};

export default RegForm;
