import React from 'react'
import './home.scss'
import Hero from '../../components/hero/Hero'

const Home = () => {
  return (
    <div className="__fw">
        <Hero/>
        <div className="__mw">
            <div className="__home_wrp">

            </div>
        </div>
    </div>
  )
}

export default Home
