import React from 'react'
import "./journalPartners.scss";
import logo_01 from "../../img/lg_1.jpeg";
import logo_02 from "../../img/lg_2.jpg";
import logo_03 from "../../img/lg_3.png";
import logo_04 from "../../img/lg_4.png";
import logo_05 from "../../img/lg_5.png";
import logo_06 from "../../img/lg_6.jpeg";
import logo_07 from "../../img/lg_7.jpeg";
import logo_08 from "../../img/lg_8.jpeg";
import logo_09 from "../../img/lg_9.jpeg";
import logo_10 from "../../img/lg_10.jpeg";

const AcademicPartners = () => {


    const journal = [
      {
        img: logo_01,
        headding: "MLTutors",
        des: "we believe that every student has the potential to excel. Our mission is to unlock that potential by providing personalized, high-quality tutoring tailored to meet the unique needs of each student. Whether you're struggling with a specific subjec...",
        link: "https://www.research.lk",
      },
      {
        img: logo_02,
        headding: "IFFHPEIG",
        des: `International Federation For Fitness Health,Physical Education And Iron Games
Website:`,
        link: "https://www.research.lk/journal/list-of-journals",
      },
      {
        img: logo_03,
        headding: "Jagadguru Kripalu University",
        des: `Jagadguru Kripalu University is being established to offer world-class education`,
        link: "https://www.research.lk/journal/list-of-journals",
      },
      {
        img: logo_04,
        headding: "NOBLE INSTITUTION",
        des: `GARI establishes an academic relationship with NOBLE INSTITUTION (Noble School of Business)`,
        link: " https://www.research.lk/journal/list-of-journals",
      },

      {
        img: logo_06,
        headding: "COLOR - SPACE - CULTURE ASSOCIATION",
        des: `The aim of the CEC Association is to defend polychrome in architecture, and in particular the idea that colored culture is an essential dimension of urban societies.`,
        link: "https://www.research.lk/journal/list-of-journals",
      },
      {
        img: logo_07,
        headding:
          "AUSTRALASIAN INSTITUTE OF AYURVEDIC STUDIES (AIAS) & PLANET AYURVEDA",
        des: `AUSTRALASIAN INSTITUTE OF AYURVEDIC STUDIES (AIAS) & PLANET AYURVEDA`,
        link: "https://www.research.lk/journal/list-of-journals",
      },
      {
        img: logo_08,
        headding: "PLANET AYURVEDA",
        des: `PLANET AYURVEDA`,
        link: "https://www.research.lk/journal/list-of-journals",
      },
      {
        img: logo_09,
        headding: "Department of Community Medicine",
        des: `Mysore is a centre for education. University of Mysore was established in 1916. This was the sixth university to be established in India`,
        link: "https://www.research.lk/journal/list-of-journals",
      },
      {
        img: logo_10,
        headding: "Department of Community Medicine",
        des: `Mysore is a centre for education. University of Mysore was established in 1916. This was the sixth university to be established in India`,
        link: "https://www.research.lk/journal/list-of-journals",
      },
    ];



  return (
    <div className="__fw">
      <div className="__mw">
        <div className="journal_wrp">
          <div>
            <h5>Academic Partners</h5>
          </div>
          <div className="journalCards">
            {journal.map((item, i) => (
              <div className="card" key={i}>
                <div className="img">
                  <div className="img_in">
                    <img src={item.img} alt="" />
                  </div>
                </div>
                <div className="p_dec">
                  <h6>{item.headding}</h6>
                  <p>{item.des}</p>
                  <small>
                    Website: <a href={item.link}>{item.link}</a>{" "}
                  </small>
                  <small>Online: {item.issn} </small>
                  <span>More Info: Click Here</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}


export default AcademicPartners
