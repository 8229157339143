import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/home/Home";
import Navbar from "./components/navBar/Navbar";
import Footer from "./components/footer/Footer";
import AboutUs from "./Pages/aboutUs/AboutUs";
import AdvisoryTeam from "./Pages/advisory-team/AdvisoryTeam";
import Publications from "./Pages/Publications/Publications";
import JournalPartners from "./Pages/journalPartners/JournalPartners";
import AcademicPartners from "./Pages/journalPartners/AcademicPartners";
import Organizers from "./Pages/advisory-team/Organizers";
import Ambassadors from "./Pages/advisory-team/Ambassadors";
import PresenterApply from "./Pages/registration/PresenterApply";
import Keynote from "./Pages/registration/Keynote";
import AcademicsPartnerReg from "./Pages/registration/AcademicsPartnerReg";
import Ambassador from "./Pages/registration/Ambassador";
import Sponsors from "./Pages/registration/Sponsors";
import Language from "./Pages/registration/Language";
import ScientificReviewer from "./Pages/registration/ScientificReviewer";
import JournalEditorialBoard from "./Pages/registration/JournalEditorialBoard";

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/advisory-team" element={<AdvisoryTeam />} />
        <Route path="/publications" element={<Publications />} />
        <Route path="/journal-partners" element={<JournalPartners />} />
        <Route path="/academic-partners" element={<AcademicPartners />} />
        <Route path="/organizers" element={<Organizers />} />
        <Route path="/ambassadors" element={<Ambassadors />} />
        <Route path="/presenter-apply" element={<PresenterApply />} />
        <Route path="/co-chair-keynote-apply" element={<Keynote />} />
        <Route
          path="/journal-editorial-board-apply"
          element={<JournalEditorialBoard />}
        />
        <Route
          path="/scientific-reviewer-apply"
          element={<ScientificReviewer />}
        />
        <Route path="/ambassadors-apply" element={<Ambassador />} />
        <Route path="/sponsor-apply" element={<Sponsors />} />
        <Route path="/present-language" element={<Language />} />
        <Route
          path="/academics-partner-apply"
          element={<AcademicsPartnerReg />}
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
