import React from 'react'
import "./copyR.scss";

const CopyR = () => {

    const year = new Date().getFullYear()

    
  return (
    <div className="copy_wrp">
      <small>
        <a href="https://redesigno.com/">
          <span>Designed by ReDesigno</span>
        </a>
      </small>
      <small>
        Copyright © {year} Copyright 2015 GARI Conference. All Rights Reserved
      </small>
    </div>
  );
}

export default CopyR
