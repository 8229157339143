import React from 'react'
import './footer.scss'
import { Link } from 'react-router-dom';
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoMail } from "react-icons/io5";
import { MdLocationOn } from "react-icons/md";
import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import CopyR from '../copy/CopyR';

const Footer = () => {
  return (
    <div
      className="__fw"
      style={{ backgroundColor: "#0f1218", borderTop: "3px solid #00afef",marginTop:'2rem' }}
    >
      <div className="__mw">
        <div className="footer">
          <div className="s_i">
            <img src={require("../../img/_logo.png")} alt="" />
            <br />
            <small>
              We established on 2010 as independent service provider then wider
              range of experience GARI became a powerful arm of scientific
              research conference organizer in the industry. Sri Lanka has
              more-than 2500 years long history of culture and Education, that
              exposure we share with world today.
            </small>
          </div>

          <div className="s_ii">
            <h5>Help Desk</h5>
            <ul>
              <Link to={"/about"} className="cName">
                <li>About GARI</li>
              </Link>
              <Link to={"/"} className="cName">
                <li>FAQ</li>
              </Link>
              <Link to={"/about"} className="cName">
                <li>Visit Sri Lanka</li>
              </Link>
              <Link to={"/service"} className="cName">
                <li>Testimonials</li>
              </Link>
              <Link to={"/blog"} className="cName">
                <li>GARI Publications</li>
              </Link>
              <Link to={"/contact"} className="cName">
                <li>Research Library</li>
              </Link>
              <Link to={"/login"} className="cName">
                <li>Blog</li>
              </Link>
            </ul>
          </div>

          <div className="s_ii">
            <h5>Guidlines</h5>
            <ul>
              <Link to={"/"} className="cName">
                <li>Abstract</li>
              </Link>
              <Link to={"/about"} className="cName">
                <li>Full Paper</li>
              </Link>
              <Link to={"/service"} className="cName">
                <li>Publication</li>
              </Link>
              <Link to={"/blog"} className="cName">
                <li>Presentation</li>
              </Link>
              <Link to={"/contact"} className="cName">
                <li>Research Library</li>
              </Link>
              <Link to={"/login"} className="cName">
                <li>Journl</li>
              </Link>
            </ul>
          </div>

          <div className="s_iii">
            <h5>Guidlines</h5>

            <div style={{ marginBottom: "10px" }}>
              <span>
                Global Academic Research Institute 87/23 A, Kohilakotuwa
                Rd,Neelammahara, Maharagama, Colombo Sri Lanka
              </span>
            </div>
          </div>
          <div className="s_iv">
            <h5>Follow us</h5>
            <div className="so">
              <a
                href="https://www.facebook.com/profile.php?id=61573002093140"
                target="blank"
              >
                <img
                  style={{ width: "30px" }}
                  src={require("../../img/fb.png")}
                  alt=""
                />
              </a>
              <a href="https://www.instagram.com/aaacgroup/" target="blank">
                <img
                  style={{ width: "30px" }}
                  src={require("../../img/instagram.png")}
                  alt=""
                />
              </a>
              <a
                href="https://www.linkedin.com/company/aaac-accounting-bookkeeping-llc/"
                target="blank"
              >
                <img
                  style={{ width: "30px" }}
                  src={require("../../img/link.png")}
                  alt=""
                />
              </a>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <h6
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {" "}
                <BiSolidPhoneCall />
                Call
              </h6>
              <small>(+94) 77 394 0838</small>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <h6
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {" "}
                <IoMail /> Email
              </h6>
              <small>helpdesk@gariteam.com</small>
            </div>
          </div>
        </div>
        <hr />
        <CopyR />
      </div>
    </div>
  );
}

export default Footer
