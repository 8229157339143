import React from 'react'
import "./registration.scss";
import RegForm from '../../components/form/RegForm';

const ScientificReviewer = () => {
  return (
    <div className="__fw">
      <div className="__mw">
        <div className="reg_wrp">
          <div>
            <h5>Scientific Reviewer / Co Chair / Keynote</h5>
          </div>
          <div className="reg_in">
            <div className="reg_left">
              <p>
                he GARI conference is organizing international conference all
                around the world Every month for targeting diverse community of
                academics, researchers, developers, creators, educators,
                practitioners ,professionals, industry leaders &
                representatives, designers, students, and other persons who are
                interesting in. Our Ultimate theme is “Making practitioners for
                Tomorrow”. To achieve our objectives we need qualified
                Conference Co-Chairs, Key note Speakers, Scientific Reviewers,
                and Session Chairs.
                <br />
                <br />
                The <em>Conference Chair</em> is responsible for all aspects of
                the conference, from coordinating the planning process and
                running meetings, to choosing conference content, publicity,
                proofing the printed program, working with sponsors and vendors,
                working with the hotel, etc. The details of the project as a
                whole are outlined in the descriptions of the subcommittees; the
                Chair is responsible for coordinating all the work in the
                subcommittees. The main responsibility of the Conference Chair
                is to create a conference that provides valuable content for the
                attendees including vendors, sponsors, volunteers, and
                registrants. GARI has expertise supportive team for you.
                <br />
                <br />
                Our <em>key note speakers</em> should have sound academic and
                industry background on their subjects. Prepare & present
                industry findings on leading-edge research, innovative
                techniques and emerging trends through case studies, panel
                discussions and how-to models. A Keynote Speaker should be able
                to capture the essence of separate conference and be able to
                highlight it to our audience in a short period of time.
                <br />
                <br />
                <em>Scientific Reviewers</em> or Peer review is the principal
                mechanism by which the quality of research is judged. Most
                funding decisions in science and the academic advancement of
                scientists are based on peer-reviewed publications. The main
                objectives of <em>Scientific Reviewers</em> to provide written,
                unbiased feedback in a timely manner on the scholarly merits and
                the scientific value of the work, together with the documented
                basis for the reviewer’s opinion
              </p>
            </div>
            <div className="reg_right">
              <RegForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScientificReviewer
