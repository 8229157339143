import { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import "./navBar.css";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleMouseEnter = (menu) => {
    setActiveDropdown(menu);
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  return (
    <div className="__fw">
      <div className="__mw">
        <nav className="NavbarItems" onMouseLeave={handleMouseLeave}>
          <div className="Logo_wrp">
            <Link to="/">
              <img
                className="logo_img"
                src={require("../../img/logo.png")}
                alt=""
              />
            </Link>
          </div>
          <div className="Hamburger-Cross-Icons">
            {open ? (
              <IoClose color="black" size={27} onClick={handleClickClose} />
            ) : (
              <FaBars color="black" size={27} onClick={handleClickOpen} />
            )}
          </div>
          <ul className={open ? "MenuItems active" : "MenuItems"}>
            <div
              className="subNav"
              onMouseEnter={() => handleMouseEnter("about")}
            >
              <li className="nv">About</li>
              {activeDropdown === "about" && (
                <div className="dropDown">
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">AboutGARI</li>
                  </Link>
                  <Link to={"/advisory-team"} onClick={handleClickClose}>
                    <li className="nv">Advisory-team</li>
                  </Link>
                  <Link to={"/publications"} onClick={handleClickClose}>
                    <li className="nv">publications</li>
                  </Link>
                  <Link to={"/journal-partners"} onClick={handleClickClose}>
                    <li className="nv">journal-partners</li>
                  </Link>
                  <Link to={"/academic-partners"} onClick={handleClickClose}>
                    <li className="nv">Academic Partners</li>
                  </Link>
                  <Link to={"/ambassadors"} onClick={handleClickClose}>
                    <li className="nv">Country Ambassador</li>
                  </Link>
                  <Link to={"/organizers"} onClick={handleClickClose}>
                    <li className="nv">Organizing Teams</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">CSR Project</li>
                  </Link>
                </div>
              )}
            </div>

            <div
              className="subNav"
              onMouseEnter={() => handleMouseEnter("conference")}
            >
              <li className="nv">Conference</li>
              {activeDropdown === "conference" && (
                <div className="dropDown">
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Upcoming on 2025</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Upcoming on 2026</li>
                  </Link>
                </div>
              )}
            </div>

            <div
              className="subNav"
              onMouseEnter={() => handleMouseEnter("speakers")}
            >
              <li className="nv">Speakers</li>
              {activeDropdown === "speakers" && (
                <div className="dropDown">
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">
                      Co-chairs and <br /> Keynote Speakers
                    </li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Presenters - Upcoming</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Scientific Committee</li>
                  </Link>
                </div>
              )}
            </div>

            <div
              className="subNav"
              onMouseEnter={() => handleMouseEnter("registration")}
            >
              <li className="nv">Registration</li>
              {activeDropdown === "registration" && (
                <div className="dropDown">
                  <Link to={"/presenter-apply"} onClick={handleClickClose}>
                    <li className="nv">Presenter / Participant</li>
                  </Link>
                  <Link
                    to={"/co-chair-keynote-apply"}
                    onClick={handleClickClose}
                  >
                    <li className="nv">Co-Chair / Keynote</li>
                  </Link>
                  <Link
                    to={"/academics-partner-apply"}
                    onClick={handleClickClose}
                  >
                    <li className="nv">Academics Partner</li>
                  </Link>
                  <Link
                    to={"/scientific-reviewer-apply"}
                    onClick={handleClickClose}
                  >
                    <li className="nv">Scientific Reviewer</li>
                  </Link>
                  <Link
                    to={"/journal-editorial-board-apply"}
                    onClick={handleClickClose}
                  >
                    <li className="nv">
                      Apply for Journal <br /> Editorial Board
                    </li>
                  </Link>
                  <Link to={"/ambassadors-apply"} onClick={handleClickClose}>
                    <li className="nv">Country Ambassador</li>
                  </Link>
                  <Link to={"/sponsor-apply"} onClick={handleClickClose}>
                    <li className="nv">Sponsor Apply</li>
                  </Link>
                  <Link to={"/present-language"} onClick={handleClickClose}>
                    <li className="nv">Present Your Language</li>
                  </Link>
                </div>
              )}
            </div>
            <div
              className="subNav"
              onMouseEnter={() => handleMouseEnter("researchLibrary")}
            >
              <li className="nv">Research Library</li>
              {activeDropdown === "researchLibrary" && (
                <div className="dropDown">
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Library</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Past Proceedings</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Paper Submition</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Abstract Guideline</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Full Paper Guideline</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Presentation Guideline</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Journal Manuscript</li>
                  </Link>
                </div>
              )}
            </div>

            <div
              className="subNav"
              onMouseEnter={() => handleMouseEnter("history")}
            >
              <li className="nv">History</li>
              {activeDropdown === "history" && (
                <div className="dropDown">
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Past Conferences</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">
                      Co-chairs and <br /> Keynote Speakers
                    </li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Past Presenters</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Past Award Winners</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">
                      Past Scientific <br /> Committee
                    </li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Video Gallery</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Image Gallery</li>
                  </Link>
                </div>
              )}
            </div>

            <div
              className="subNav"
              onMouseEnter={() => handleMouseEnter("contact")}
            >
              <li className="nv">Contact</li>
              {activeDropdown === "contact" && (
                <div className="dropDown">
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Contact Us</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Visa Apply</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Accommodation</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Conference Tour</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Visit Sri Lanka</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Venue</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Alumini</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">FAQ</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Sponsors</li>
                  </Link>
                  <Link to={"/about"} onClick={handleClickClose}>
                    <li className="nv">Donation</li>
                  </Link>
                </div>
              )}
            </div>

            <Link className="nav-button">
              <li>Login</li>
            </Link>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
