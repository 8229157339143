import React from 'react'
import "./advisoryTeam.scss";
import img_01 from "../../img/01.jpg";
import img_02 from "../../img/02.jpg";
import img_03 from "../../img/03.jpg";
import img_04 from "../../img/04.jpg";
import img_05 from "../../img/05.jpg";
import img_06 from "../../img/06.jpg";
import img_07 from "../../img/07.jpg";
import img_08 from "../../img/08.jpg";
import img_09 from "../../img/09.jpg";
import img_10 from "../../img/10.jpg";
import img_11 from "../../img/11.jpg";
import img_12 from "../../img/12.jpg";
import img_13 from "../../img/13.jpg";
import img_14 from "../../img/14.jpg";
import img_15 from "../../img/15.jpg";
import img_16 from "../../img/16.jpg";
import img_17 from "../../img/17.jpg";
import img_18 from "../../img/18.jpg";
import img_19 from "../../img/19.jpg";
import img_20 from "../../img/20.jpg";
import img_21 from "../../img/21.jpg";

const AdvisoryTeam = () => {

  const team = [
      {
        img: img_01,
        name: "Dr. Terseer Hemben",
        des: "Advisory Board Member / Country Ambassador / Co-chair AND University Advisory Board, University of Riverside University of Riverside, USA",
      },
      {
        img: img_02,
        name: "Janet Best",
        des: "Co Chair Fashion Colour Management Specialist, UK",
      },
      {
        img: img_03,
        name: "Professor, Dr. Robert C. Schneider Program Director",
        des: "The College at Brockport, State University of New York, USA",
      },
      {
        img: img_04,
        name: "Dr.S.Ramaratnam",
        des: "Vice Chancellor,Jagadguru Kripalu University, India",
      },
      {
        img: img_05,
        name: "Dr. S. Ajit Director",
        des: "Australasian Institute of Ayurvedic Studies, Australia",
      },
      {
        img: img_06,
        name: "Prof. Dr. Yubaraj Sangroula",
        des: " Executive Director Kathmandu School of Law, Nepal",
      },
      {
        img: img_07,
        name: "Dr. Kalaivani Vivehananthan",
        des: "Co-Chair Head/Department of Basic Sciences, Department of Basic Sciences, Faculty of Health Sciences, The Open University of Sri Lanka",
      },
      {
        img: img_08,
        name: "Dr. Bisant Kaur",
        des: " Co- Chair Senior Director at Federal Agricultural Marketing Authority (FAMA), Malaysia",
      },
      {
        img: img_09,
        name: "Prof. Dr. Dimiter Georgiev Velev",
        des: "Co-Chair Dept. of Information Technologies and Communications Faculty of Applied Informatics and Statistics, University of National and World Economy, Bulgaria",
      },
      {
        img: img_10,
        name: "Professor Ruth Alas",
        des: " Professor of Management Chair of Management Institute, Estonian Business School (2006 – 2019), Estonia",
      },
      {
        img: img_11,
        name: "Dr. Soumen Mukherjee",
        des: " Associate Professor & Instructor-in-charge Department of English & Communication Studies, Presidency University, India",
      },
      {
        img: img_12,
        name: "Dr. Larissa Noury",
        des: ` Professor/President Artist, Architect & Designer-coloriste, Professor of Itecom Art & Design High School, President-founder " Colour-Space-Culture/Couleur-Espace-Culture", France`,
      },
      {
        img: img_13,
        name: "Dr. Bhavik U Swadia",
        des: " Assistant Professor, GLS University, S.M. Patel Institute of Commerce, India",
      },
      {
        img: img_14,
        name: "Niharika Gautam",
        des: " Business Head- Apparel & Fashion industry 'Exceptional Woman of Excellence 2018' awardee, Panelist & Speaker - WEF, Mentor - MoMA (New York), UK",
      },
      {
        img: img_15,
        name: "Dennis Relojo",
        des: " Ambassador / Advisory Team University of Hertfordshire, UK",
      },
      {
        img: img_16,
        name: "Dr. D. Eluwawalage",
        des: " Assistant Professor Professional Historian, Albright College, USA",
      },
      {
        img: img_17,
        name: "Dr.A. BlessingMary",
        des: " Assistant Professor of Education, Mother Teresa Women’s University, India",
      },
      {
        img: img_18,
        name: "Dr. Kaukab Azeem",
        des: "President of IFFPHPEIG & Editor-in-Chief IJFPHIG, Physical Education Department, King Fahd University of Petroleum & Minerals, Saudi Arabia",
      },
      {
        img: img_19,
        name: "Prof. Athula C Gnanapala",
        des: " Dean,Faculty of Management Studies, Sabaragamuwa University of Sri Lanka",
      },
      {
        img: img_20,
        name: "Prof. Hernán E. Gil Forleo",
        des: " Professor of Economics & Finance, University of Buenos Aires (UBA), Argentina",
      },
      {
        img: img_21,
        name: "Dr. Jeanne Francoise",
        des: " Lecturer in Peace & Conflict Resolution Postgraduate Study Programme Indonesia Defense University, Indonesia",
      },
  
    ];
  return (
    <div className="__fw">
      <div className="__mw">
        <div className="adv_wrp">
          <div>
            <h5>Advisory Team</h5>
          </div>
          <div>
            <p>
              The GARI Conference Advisory Board is designed to assist in
              driving an impartial, yet informed dialogue on the rapidly
              evolving information of industry as well as offering guidance into
              overall program development. They work to ensure that the quality
              of the content provided to attendees is high-level, relevant, and
              timely. Read below to find out about the extraordinary members of
              the GARI Advisory Board
            </p>
          </div>
          <div className="team_wrp">
            <div className="team_in">
              {team.map((item, i) => (
                <div className="team_sec" key={i}>
                  <div className="t_img">
                    <div className="t_img_in">
                      <img src={item.img} alt={item.name} />
                    </div>
                  </div>
                  <div className="i_in">
                    <h6>{item.name}</h6>
                    <span>{item.des}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvisoryTeam
