import React from 'react'
import "./registration.scss";
import RegForm from '../../components/form/RegForm';

const Language = () => {
  return (
    <div className="__fw">
      <div className="__mw">
        <div className="reg_wrp">
          <div>
            <h5>Get Translation Facility</h5>
          </div>
          <div className="reg_in">
            <div className="reg_left">
              <p>
                <em>About Our Conference Interpretation Program</em>
                <br />
                GARI Conference offer special benefit for the entire
                International and Local presenters, listeners and other
                participants called “Language Interpretation” service.
                Conference interpretation is conveying a message spoken in one
                language into another. Conference interpretation enables
                participants in a multinational meeting to communicate with one
                another in a seamless fashion, making the language barrier
                almost imperceptible. The modern practice of conference
                interpretation is usually considered to date from the Nuremberg
                trials of 1945-1946. But throughout history people of different
                languages and cultures have made use of interpretation to
                communicate with and understand each other. Interpreters have
                always been involved in the development of international trade
                and cultural exchange. <br />
                <br />
                GARI Conference use ENGLISH as its Language but we know how
                people important their “Mother Language” so On pre-request GARI
                facilitate RUSSIAN, FRENCH, CHINESE, SINHALA interpreter service
                within the conference.
                <br />
                <em>The skills of the GARI conference interpreter</em>
                <br />
                Interpreters have complete mastery of their working languages,
                including an excellent command of their native language.
                <br />
                They have Good mind, level of general education, lively and
                flexible intellect, analytic capacity, also be able to
                concentrate, good memory, pleasant voice and professional
                confidentiality.
              </p>
            </div>
            <div className="reg_right">
              <RegForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Language
