import React from 'react'
import RegForm from '../../components/form/RegForm';
import "./registration.scss";

const AcademicsPartnerReg = () => {
  return (
    <div className="__fw">
      <div className="__mw">
        <div className="reg_wrp">
          <div>
            <h5>Academics Partner</h5>
          </div>
          <div className="reg_in">
            <div className="reg_left">
              <p>
                he GARI conference is organizing international conference all
                around the world Every month for targeting diverse community of
                academics, researchers, developers, creators, educators,
                practitioners ,professionals, industry leaders &
                representatives, designers, students, and other persons who are
                interesting in. Our Ultimate theme is “Making practitioners for
                Tomorrow”. To achieve our objectives we need qualified
                Conference Co-Chairs, Key note Speakers, Scientific Reviewers,
                and Session Chairs.
              </p>
            </div>
            <div className="reg_right">
              <RegForm/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcademicsPartnerReg
